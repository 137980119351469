<template>
	<div class="wrap">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						class="arrow_white"
						@click="$emit('goBack')"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">Google OTP</h2>
			</div>
		</div>
		<div class="subindex_wrap pt-50">
			<div class="container">
				<div class="subindex_mainbox type2">

					<h3>Google {{  $language.auth.otp_auth }}</h3>
					<div>
						<p class="step_tit">① {{  $language.auth.otp_message_01 }}</p>
						<div class="btn_area">
							<a
								class="btn_l btn_fill_blue otp_btn"
								href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko&gl=US"
								target="store"
							>
								<img :src="require('@/assets/image/ico_google.png')" alt="">
							</a>
							<a
								class="btn_l btn_fill_blue otp_btn"
								href="https://apps.apple.com/kr/app/google-authenticator/id388497605"
								target="store"
							>
								<img :src="require('@/assets/image/ico_app.png')" alt="">
							</a>
						</div>
					</div>

					<div>
						<p class="step_tit">② {{  $language.auth.otp_message_02 }}</p>
						<figure class="qr_area">
							<qr-code :text="text_qr_code" :size="160" style="display: inline-block;"></qr-code>
							<figcaption>{{  $language.auth.otp_barcode_message_01 }}</figcaption>
						</figure>
						<div class="bright_input flex pt-20">
							<input
								v-model="gogle_otp_key"
								type="text" readonly
							>
							<button class="btn_l btn_fill_blue ml-10" @click="copy">{{  $language.common.copy }}</button>
						</div>
					</div>

					<div>
						<p class="step_tit">③ Google {{  $language.auth.otp_auth_number }}</p>
						<div class="bright_input">
							<input
								v-model="gogle_otp_certfc_number"
								maxlength="6"
								type="text" :placeholder="$language.auth.otp_input"
							>
						</div>
					</div>

					<div class="btn_area">
						<button
							class="btn_l btn_fill_blue"
							:disabled="gogle_otp_certfc_number.length != 6"

							@click="postOtp"
						>{{  $language.common.ok }}</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	name: 'mafia1114'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.auth.google_otp
				, title: this.$language.auth.google_otp
				, not_header: true
				, not_footer: true
				, type: 'mypage'
			}
			, gogle_otp_key: ''
			, gogle_otp_certfc_number: ''
			, nickname: ''
			, member_number: ''
		}
	}
	, computed: {
		text_qr_code: function(){
			let t = ''
			if(this.member_number && this.gogle_otp_key){
				t = 'otpauth://totp/METAMAFIA(' + this.member_number + ')?secret=' + this.gogle_otp_key
			}

			return t
		}
	}
	, methods: {
		postOtp: async function(){
			console.log('postOtp')
			try{
				this.$bus.$emit('on', true)
				if(!this.gogle_otp_certfc_number){
					throw this.$language.auth.otp_input_01
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_google_change_otp
					, data: {
						member_number: this.user.member_number
						, gogle_otp_certfc_number: this.gogle_otp_certfc_number
					}
					, type: true
				})

				if(result.success){
					this.$emit('click')
				}else{
					throw result.message
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,pinClick: async function(){
			this.pin_option.pin_type = 'set'
			this.$emit('to', { name: 'mafia010'})
		}
		,pinCancel: function(){
			console.log('cancel')
			this.is_pin = false
			this.pin_option.pin_type = 'set'
		}
		, getGoogleOtpKey: async function(){
			console.log('getGoogleOtpKey')
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_google_otp_change_key
					, data: {
						member_number: this.user.member_number
						, gogle_otp_certfc_number: ''
					}
					, type: true
				})

				if(result.success){
					this.gogle_otp_key = result.data.gogle_otp_key
					this.nickname = result.data.nickname
					this.member_number = result.data.member_number
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);

			t.value = val;
			console.log('t.value', t.value)
			t.select();
			t.setSelectionRange(0, 9999)
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (){

			this.clipBoard(this.gogle_otp_key);
			this.$bus.$emit('notify',  { type: 'success', message: this.$language.auth.otp_copy_key})
		}

		, toGoogle: function(){

		}

	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getGoogleOtpKey()
	}
}
</script>